@import "/src/styles/var";
@import "/src/styles/mixins";
@import "/src/styles/grid";

.preFooter {
  background-color: $mirage950;
  padding: toRem(12) toRem(12);
  border-bottom: 1px solid $athensGray50;

  @include media-breakpoint-up(lg) {
    padding: toRem(24) toRem(32);
  }

  :global(.MuiTypography-root) {
    font-size: toRem(36) !important;

    @include media-breakpoint-up(lg) {
      font-size: toRem(80) !important;
    }
  }

  .chapter {
    background-color: $mirage200;
    display: flex;
    justify-content: center;
    align-items: center;
    width: toRem(46);
    height: toRem(46);
    border-radius: toRem(46);
    flex: 0 0 toRem(46);

    &:global(.MuiTypography-root) {
      color: $mirage950;
    }

    @include media-breakpoint-up(lg) {
      width: toRem(96);
      height: toRem(96);
      flex: 0 0 toRem(96);
      border-radius: toRem(96);
    }
  }

  .icon {
    margin-left: auto;
  }
}

