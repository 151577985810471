@import "/src/styles/var";
@import "/src/styles/mixins";
@import "/src/styles/grid";

.footer {
  color: $white;
  background: $mirage950;
  position: relative;
  z-index: 900;

  :global(.darkMode) & {
    filter: invert(1);
    background: $grey100;
  }

  a {
    color: $white;
  }

  .linkDesc {
    color: $white !important;
    font-size: toRem(16) !important;
    margin-left: toRem(-8);
  }

  .footerContent {
    padding: toRem(51) toRem(32) !important;

    @include media-breakpoint-down(md) {
      padding: toRem(24) toRem(32) toRem(36) !important;
    }

    :global(.MuiTypography-root) {
      text-transform: uppercase;
    }

    .footerMenu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: toRem(27);
      margin-top: toRem(27);
      font-weight: 500;

      &:first-child {
        margin-top: toRem(64);
      }

      @include media-breakpoint-up(md) {
        gap: toRem(32);
        margin-top: 0;
      }

      //  fix safari < 15
      @supports not (accent-color: red) {
        gap: 0;

        > * + * {
          margin-top: toRem(27);

          @include media-breakpoint-up(md) {
            margin-top: toRem(32);
          }
        }
      }

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: toRem(16);
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -2px;
          left: 0;
          background: $white;
          transform: scale(0);
          transition: transform 0.25s;
        }

        &:hover,
        &:active,
        &:focus,
        &.active {
          cursor: pointer;
          color: $white;

          &:after {
            transform: scale(1);
          }
        }
      }

      &.footerMenuColOne {
        text-transform: uppercase;
        margin-top: toRem(64);

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }

      &.footerMenuColThree {
        font-weight: 300;
      }
    }
  }

  .copyright {
    padding: toRem(51) toRem(32) !important;
    background: $mirage200;
    :global(.MuiTypography-root) {
      color: $mirage950;
      line-height: 1.2;
      text-transform: uppercase;
      font-size: toRem(14);

      @include media-breakpoint-up(md) {
        font-size: toRem(16);
      }
    }

    a {
      color: $mirage950;
    }

    .copyrightMenu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: toRem(6);
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        flex-direction: column;
        align-items: flex-end;
      }

      :after {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: toRem(24);
      }

      //  fix safari < 15
      @supports not (accent-color: red) {
        gap: 0;

        > * + * {
          margin-left: toRem(12);

          @include media-breakpoint-up(md) {
            margin-left: toRem(24);
          }
        }
      }

      a {
        text-decoration: underline;
        position: relative;
        font-size: toRem(10);
        color: $mirage950;
        transition: all 0.25s linear;

        font-family: "EDF2020W01-Regular";
        font-size: toRem(16);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        &:hover {
          color: $black;
        }

        &::after {
          content: "";
          height: 100%;
          width: 1px;
          background-color: $mirage950;
          position: absolute;
          right: -toRem(6);
          margin-left: toRem(10);
          top: 0;

          @include media-breakpoint-up(lg) {
            right: -toRem(12);
          }
        }

        &:last-child {
          &::after {
            @include media-breakpoint-down(md) {
              content: none;
            }

            @include media-breakpoint-up(lg) {
              content: none;
            }
          }
        }
      }
    }
  }
}
