@import "/src/styles/var";
@import "/src/styles/mixins";

.navLongFormContainer {
  background-color: $mirage50;
  border: 1px solid $mirage950;
  position: fixed;
  bottom: toRem(47);
  left: 50%;
  transform: translate(-50%);
  z-index: 900;
  height: toRem(64);

  .button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: toRem(10);
    overflow: hidden;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      transition: all 0.25s ease-in;
    }

    &.up {
      width: toRem(100);
      padding: toRem(10);

      &:before {
        width: toRem(42);
        height: toRem(42);
        background-color: $mirage950;
      }
  
      &:hover {
        &:before {
          width: toRem(96);
          height: toRem(96);
        }
      }
    }

    &.chapter {      
      width: toRem(120);
      padding: toRem(10);
  
      > :global(.MuiTypography-root) {
        color: $mirage950 !important;
      }
      &:before {
        width: toRem(56);
        height: toRem(56);
        border-radius: 50%;
        background-color: $mirage100;
      }
  
      &:hover {
        &:before {
          width: toRem(96);
          height: toRem(96);
        }
      }
  
      &.active {
        &:before {
          width: toRem(96);
          height: toRem(96);
          background-color: $emerald500;
        }
      }
    }
  }
}

.floatingButton {
  position: fixed;
  bottom: toRem(16);
  left: 50%;
  transform: translateX(-50%);
  background-color: $mirage950;
  color: $athensGray50;
  z-index: 99999;

  &:hover {
    background-color: $mirage950;
    color: $athensGray50;
  }

  .icon {
    font-size: toRem(36);
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: rotate(0deg),
  }

  &.open {
    background-color: $athensGray50;
    color: $mirage950;

    .icon {
      transform: rotate(180deg);
    }
  }
}

.modalContent {
  background-color: $white;
  height: 100%;
  position: relative;
  z-index: 10;

  .navContainer {
    border-bottom: 1px solid $mirage950;
    .button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: toRem(117);
      padding: toRem(10);
      overflow: hidden;
      cursor: pointer;
  
      :global(.MuiTypography-root) {
        color: $mirage950;
        font-family: "mixta-sharp";
        font-size: toRem(72);
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -1.44px;
      }
  
      &::before {
        content: "";
        display: block;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        transition: all 0.25s ease-in;
        width: toRem(136);
        height: toRem(136);
        border-radius: 50%;
        background-color: $mirage100;
      }

      &.active {
        &:before {
          background-color: $emerald500;
        }
      }
    }
  }
  

  .modalFooter {
    position: absolute;
    height: toRem(80);
    background-color: $mirage950;
    bottom: 0;
    width: 100%;
  }
}